import React from "react"
import { Link } from "gatsby"
import { getUserMoso } from "../../api/content"
import SCButton from "../buttons/SCButton"
import { FaRegHandshake } from "@react-icons/all-files/fa/FaRegHandshake"
import { Input, InputAdornment } from "@material-ui/core"
import { validateNumber } from "./helper/validationsRegexFuncs"
import Spinner from "../Spinner"

const PartnerCode = ({ onSignInRequest, onSignUpPartnerForm, setNameHidden }) => {
  const [userPartnerCode, setUserPartnerCode] = React.useState({
    code: "",
  })

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState("")

  const updateProperty = (property, value) => {
      if (validateNumber(value) || value === "") {
      if (~~value || value === ""|| parseInt(value) === 0) {
        setError("")
        return setUserPartnerCode({ ...userPartnerCode, [property]: value })
      }
    }
    setError("Solo números permitidos")
  }

  const sendCode = () => {
    setError("")
    if (userPartnerCode.code === "") {
      return setError("Ingresa tú número de socio")
    }
    setLoading(true)

    getUserMoso(userPartnerCode.code).then(response => {

      setLoading(false)
      if (response.status === 200) {
        setNameHidden(response)
        return onSignUpPartnerForm()
      }
      if(response.status === 404){
        return setError(response.message)
      }
      setError(response)
    })

  }

  return (
    <>
      <h4 className="text-sc-titles text-center">Ingresa tú número de socio</h4>
      <main>
        <div className="w-full  mb-8">
          <Input
            className="w-full"
            type="text"
            placeholder="Número de Socio"
            value={userPartnerCode.code}
            onChange={({ target }) => updateProperty("code", target.value)}
            startAdornment={
              <InputAdornment position="start">
                <FaRegHandshake style={{ fontSize: "24px" }} />
              </InputAdornment>
            }
          />
        </div>
        <p className="text-red-600 text-center">{error}</p>
        <div className="w-64 mx-auto mb-5">
          {
            !loading ? <SCButton onClick={sendCode}><b>Aceptar</b> </SCButton> : <Spinner size={40} message={'Cargando'} sizeMessage={true} />
          }
        </div>
      </main>
      <div className="text-center">
        <p className="hidden md:block">
          ¿Ya tienes una cuenta?{" "}
          <span
            className="text-sc-titles"
            onClick={onSignInRequest}
            style={{ cursor: "pointer" }}
          >
            <b>Inicia sesión</b>
          </span>
        </p>
        <p className="block md:hidden">
          ¿Ya tienes una cuenta?
          <Link
            className="inline-block md:hidden mb-6 mt-6 text-sc-titles"
            to="/pwa/account/login"
            style={{ cursor: "pointer" }}
          >
            <b> Inicia sesión</b>
          </Link>
        </p>
      </div>
    </>
  )
}

export default PartnerCode
