import * as React from "react"
import { Modal, Input, Checkbox, FormControlLabel } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MailIcon from "@material-ui/icons/Mail"
import TextField from "@material-ui/core/TextField"
import { StaticImage } from "gatsby-plugin-image"
import InputAdornment from "@material-ui/core/InputAdornment"
import CheckIcon from "@material-ui/icons/Check"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import IconButton from "@material-ui/core/IconButton"
import LockIcon from "@material-ui/icons/Lock"
import PersonIcon from "@material-ui/icons/Person"
import SCButton from "../buttons/SCButton"
import firebase from "firebase"
import { Link } from "gatsby"
import { createUser } from "../../api/content"
import InputTexfieldRegex from "./InputTexfieldRegex"
import { BsFillPeopleFill } from "@react-icons/all-files/bs/BsFillPeopleFill"
import {
  validateEmail,
  validateNames,
  validateApellidos,
} from "./helper/validationsRegexFuncs.js"

const SignUpForm = ({
  onSignInRequest,
  onPartnerCodeRequest,
  onSignUpSuccess }) => {
  const [userInfo, setUserInfo] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    termsOfServiceAccepted: false,
    privacyAccepted: false,
  })

  const [showPassword, setShowPassword] = React.useState(false)
  const [error, setError] = React.useState()
  const alphanumericRegex = /[0-9]/
  const upperCaseRegex = /[A-Z]/

  const signUp = () => {
    if (validate()) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(userInfo.email, userInfo.password)
        .then(credentials => {
          if (credentials.user) {
            createUser({
              email: userInfo.email,
              password: userInfo.password,
              alias: userInfo.email,
              firebaseId: credentials.user.uid,
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
            })
              .then()
              .catch(error => {
                console.log(error)
              })
            if (onSignUpSuccess) {

              // // GTM Event.'
              if(window.gtag){
                window.gtag('event', 'registro', {
                    'event_category': 'custom',
                    'event_label': 'registro_form',
                    'value': 1
                  });
              }
              // gtag('event', 'registro', {
              //   'event_category': 'custom',
              //   'event_label': 'registro_form',
              //   'value': 1
              // });

              onSignUpSuccess()
            }
          }
        })
        .catch(error => {
          setError(authMessageError(error.code))
        })
    }
  }

  const updateProperty = (property, value) => {
    if (property === 'password') {
      setUserInfo({ ...userInfo, [property]: value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") })
    } else {
      setUserInfo({ ...userInfo, [property]: value })
    }
  }
  const validate = () => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const acentos = /[áéíóúÁÉÍÓÚ]/;
    const charEspecial = /[@$?¡\-_]/;

    if (!userInfo.firstName) {
      setError("Ingresa tu nombre")
      return false
    }
    if (userInfo.firstName.length <= 1) {
      setError("Nombre demasiado corto")
      return false
    }
    if (!userInfo.lastName) {
      setError("Ingresa tus apellidos ")
      return false
    }
    if (userInfo.lastName.length <= 1) {
      setError("Apellido demasiado corto")
      return false
    }
    if (!userInfo.email) {
      setError("Ingresa un correo electrónico ")
      return false
    }
    if (!regex.test(userInfo.email)) {
      setError("Formato de correo electrónico incorrecto")
      return false
    }
    if (!userInfo.password) {
      setError("Debes crear una contraseña")
      return false
    } else if (!upperCaseRegex.test(userInfo.password)) {
      setError("La contraseña debe de tener al menos una letra en mayúscula")
      return false
    }
    if (!charEspecial.test(userInfo.password)) {
      setError("La contraseña debe de contener al menos un caracter especial: @ $ ? ¡ \ - _")
      return false
    }

    if (acentos.test(userInfo.password)) {
      setError("Los acentos no están permitidos")
      return false
    }
    if (!alphanumericRegex.test(userInfo.password)) {
      setError("La contraseña debe contener letras y números.")
      return false
    }
    if (userInfo.password.length < 8) {
      setError("La contraseña debe mayor a 8 caracteres")
      return false
    }
    if (!userInfo.termsOfServiceAccepted) {
      setError("Debes de aceptar los términos y condiciones para usar Shift ")
      return false
    }
    if (!userInfo.privacyAccepted) {
      setError("Debes de aceptar el aviso de privacidad para usar Shift ")
      return false
    }
    return true
  }

  const authMessageError = code => {
    let message = code
    switch (code) {
      case "auth/invalid-email":
        message = "Formato de correo electrónico incorrecto"
        break
      case "auth/weak-password":
        message = "Contraseña demasiado débil"
      case "auth/email-already-in-use":
        message = "Ya existe un usuario registrado con este correo"
      default:
        break
    }
    return message
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <main>
      <h3 className="text-sc-black">Regístrate</h3>

      <div className="w-full mb-8">
        <InputTexfieldRegex
          onChange={userInfo.firstName}
          setOnChange={updateProperty}
          property={"firstName"}
          validateFunc={validateNames}
          placeHolder={"Nombre"}
          type="string"
        >
          <PersonIcon style={{ color: "#6B6B6B" }} />
        </InputTexfieldRegex>
      </div>

      <div className="w-full mb-8">
        <InputTexfieldRegex
          onChange={userInfo.lastName}
          setOnChange={updateProperty}
          property={"lastName"}
          validateFunc={validateNames}
          placeHolder={"Apellidos"}
          type="string"
        >
          <PersonIcon style={{ color: "#6B6B6B" }} />
        </InputTexfieldRegex>
      </div>
      <div className="w-full mb-8">
        <InputTexfieldRegex
          onChange={userInfo.email}
          setOnChange={updateProperty}
          property={"email"}
          validateFunc={validateEmail}
          placeHolder={"Email"}
          type="email"
        >
          <MailIcon style={{ color: "#6B6B6B" }} />
        </InputTexfieldRegex>
      </div>

      <div className="w-full  mb-8">
        <Input
          className="w-full"
          id="standard-adornment-password"
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          value={userInfo.password}
          onChange={e => {
            updateProperty("password", e.target.value)
          }}
          startAdornment={
            <InputAdornment position="start">
              <LockIcon style={{ color: "#6B6B6B" }}></LockIcon>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className="flex justify-center -mt-2 mb-3">
        <div className="flex items-center cursor-pointer w-max"
          style={{ backgroundColor: '#00cea9', padding: '10px 20px', borderRadius: '30px', color: '#000' }}
          onClick={onPartnerCodeRequest}>
          <BsFillPeopleFill className="mr-2" style={{ fontSize: 24 }} />
          <h4 className="mb-1 font-bold text-sc-secondary" style={{ color: '#000' }} >Soy Socio</h4>
        </div>
      </div>
      <div>
        <div className="text-center">
          <p className="">
            <Checkbox
              color="default"
              value={userInfo.termsOfServiceAccepted}
              onChange={e => {
                updateProperty("termsOfServiceAccepted", e.target.checked)
              }}
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            He leído y acepto los
            <span className="text-sc-titles" style={{ cursor: "pointer" }}>
              <a href="/legal/terms" target="_blank">
                <b className="text-sc-primary"> Terminos y condiciones </b>
              </a>
            </span>
          </p>
          <p className="">
            <Checkbox
              color="default"
              value={userInfo.privacyAccepted}
              onChange={e => {
                updateProperty("privacyAccepted", e.target.checked)
              }}
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            He leído y acepto el
            <span className="text-sc-titles" style={{ cursor: "pointer" }}>
              <a href="/legal/privacy" target="_blank">
                <b className="text-sc-primary"> Aviso de Privacidad </b>
              </a>
            </span>
          </p>
        </div>
      </div>
      {error ? (
        <div className="text-red-600 text-center mb-4">{error}</div>
      ) : null}
      <div className="mt-4 mb-4 flex justify-center">
        <div style={{ width: 250 }}>
          <SCButton onClick={signUp}>
            <b>Registrate</b>
          </SCButton>
        </div>
      </div>

      <div className="text-center">
        <p className="hidden md:block">
          ¿Ya tienes una cuenta?{" "}
          <span
            className="text-sc-titles"
            onClick={onSignInRequest}
            style={{ cursor: "pointer" }}
          >
            <b>Inicia sesión</b>
          </span>
        </p>
        <p className="block md:hidden">
          ¿Ya tienes una cuenta?
          <Link
            className="inline-block md:hidden mb-6 mt-6 text-sc-titles"
            to="/pwa/account/login"
            style={{ cursor: "pointer" }}
          >
            <b> Inicia sesión</b>
          </Link>
        </p>
      </div>
    </main>
  )
}

export default SignUpForm
