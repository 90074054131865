import React from "react"
import { Input, InputAdornment, Checkbox } from "@material-ui/core"
import MailIcon from "@material-ui/icons/Mail"
import LockIcon from "@material-ui/icons/Lock"
import SCButton from "../buttons/SCButton"
import { Link } from "gatsby"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import IconButton from "@material-ui/core/IconButton"
import firebase from "firebase"
import { createSocioUser } from '../../api/content'
import Spinner from "../Spinner"

const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const acentos = /[áéíóúÁÉÍÓÚ]/

const PartnerSignUpForm = ({ onPartnerCodeRequest, onSignInRequest, onSignUpSuccess, nameHidden }) => {

  const [userInfo, setUserInfo] = React.useState({
    firstName: nameHidden.names ?? "",
    lastName: nameHidden.lastName ?? "",
    email: "",
    password: "",
    sportCityId: nameHidden.sportCityId ?? false,
    termsOfServiceAccepted: false,
    privacyAccepted: false,
  })

  // const [sportCityId, setSportCityId] = React.useState(nameHidden.sportCityId ?? null)
  const [loading, setLoading] = React.useState(false)

  const [error, setError] = React.useState()
  const alphanumericRegex = /[0-9]/
  const upperCaseRegex = /[A-Z]/

  // React.useEffect(() => {
  //   console.log(userInfo)
  // }, [userInfo])

  const validate = () => {
    const charEspecial = /[@$?¡\-_]/

    if (!userInfo.sportCityId) {
      setError('Error de comunicación, intente de nuevo')
      return false
    }

    if (!userInfo.email) {
      setError("Ingresa un correo electrónico ")
      return false
    }
    if (!regex.test(userInfo.email)) {
      setError("Formato de correo electrónico incorrecto")
      return false
    }
    if (!userInfo.password) {
      setError("Debes crear una contraseña")
      return false
    } else if (!upperCaseRegex.test(userInfo.password)) {
      setError("La contraseña debe de tener al menos una letra en mayúscula")
      return false
    }
    if (!charEspecial.test(userInfo.password)) {
      setError(
        "La contraseña debe de contener al menos un caracter especial: @ $ ? ¡  - _"
      )
      return false
    }

    if (acentos.test(userInfo.password)) {
      setError("Los acentos no están permitidos")
      return false
    }
    if (!alphanumericRegex.test(userInfo.password)) {
      setError("La contraseña debe contener letras y números.")
      return false
    }
    if (userInfo.password.length < 8) {
      setError("La contraseña debe mayor a 8 caracteres")
      return false
    }
    if(!userInfo.termsOfServiceAccepted){
      setError("Debes de aceptar los Terminos y condiciones para usar Shift")
      return false
    }
    if (!userInfo.privacyAccepted) {
      setError("Debes de aceptar el aviso de privacidad para usar Shift ")
      return false
    }
    if (!userInfo.termsOfServiceAccepted) {
      setError("Debes de aceptar los Terminos y condiciones para usar Shift ")
      return false
    }
    return true
  }

  const updateProperty = (property, value) => {
    if (property === 'password') {
      setUserInfo({ ...userInfo, [property]: value.normalize("NFD").replace(/[\u0300-\u036f]/g, "") })
    } else {
      setUserInfo({ ...userInfo, [property]: value })
    }
  }

  const [showPassword, setShowPassword] = React.useState(false)
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const authMessageError = code => {
    let message = code
    switch (code) {
      case "auth/invalid-email":
        message = "Formato de correo electrónico incorrecto"
        break
      case "auth/weak-password":
        message = "Contraseña demasiado débil"
        break
      case "auth/email-already-in-use":
        message = "Ya existe un usuario registrado con este correo"
        break
      case "auth/internal-error":
        message = "Número de Socio ya utilizado"
        break
      default:
        break
    }
    return message
  }

  const partnerSignUp = () => {
    if (validate()) {
      setLoading(true)
      firebase
        .auth()
        .createUserWithEmailAndPassword(userInfo.email, userInfo.password)
        .then(credentials => {
          if (credentials.user) {
            createSocioUser({
              email: userInfo.email,
              password: userInfo.password,
              alias: userInfo.email,
              firebaseId: credentials.user.uid,
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              sportCityId: userInfo.sportCityId
            })
              // UserSuscripcion
              .catch(error => {
                console.log(error)
              })
            if (onSignUpSuccess) {
              setLoading(false)
              onSignUpSuccess()
            }
          }
        })
        .catch(error => {
          setLoading(false)
          setError(authMessageError(error.code))
        })
    }
  }

  return (
    <div>
      {/* <div className="text-black">N******</div> */}
      <div className="cursor-default text-black mb-2 text-center">{nameHidden ? nameHidden.nameHidden : '--'} </div>
      <div className="text-center">
        <p className="cursor-pointer" onClick={onPartnerCodeRequest}>
          ¿No ves tus iniciales?
        </p>
      </div>
      <div className="w-full  mb-8">
        <Input
          className="w-full"
          type="mail"
          placeholder="Correo Electrónico"
          value={userInfo.email}
          onChange={e => {
            updateProperty("email", e.target.value)
          }}
          startAdornment={
            <InputAdornment position="start">
              <MailIcon style={{ color: "#6B6B6B" }} />
            </InputAdornment>
          }
        //   endAdornment={
        //     <InputAdornment position="end">
        //       <MailIcon style={{ color: "#6B6B6B" }} />
        //     </InputAdornment>
        //   }
        />
      </div>
      <div className="w-full  mb-8">
        <Input
          className="w-full"
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          value={userInfo.password}
          onChange={e => {
            updateProperty("password", e.target.value)
          }}
          startAdornment={
            <InputAdornment position="start">
              <LockIcon style={{ color: "#6B6B6B" }}></LockIcon>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>     
      <div className="text-center">
        <p className="">
          <Checkbox
            color="default"
            value={userInfo.termsOfServiceAccepted}
            onChange={e => {
              updateProperty("termsOfServiceAccepted", e.target.checked)
            }}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          He leído y acepto los
          <span className="text-sc-titles" style={{ cursor: "pointer" }}>
            <a href="/legal/terms" target="_blank">
              <b className="text-sc-primary"> Términos y condiciones </b>
            </a>
          </span>
        </p>
        <p className="">
          <Checkbox
            color="default"
            value={userInfo.privacyAccepted}
            onChange={e => {
              updateProperty("privacyAccepted", e.target.checked)
            }}
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          He leído y acepto el
          <span className="text-sc-titles" style={{ cursor: "pointer" }}>
            <a href="/legal/privacy" target="_blank">
              <b className="text-sc-primary"> Aviso de Privacidad </b>
            </a>
          </span>
        </p>
      </div>
      {error ? (
        <div className="text-red-600 text-center mb-4">{error}</div>
      ) : null}
      {
        !loading ? <div className="w-64 mx-auto mb-5">
          <SCButton onClick={partnerSignUp}>
            <b>Aceptar</b>
          </SCButton>
        </div> : <Spinner size={30} message="Cargando" sizeMessage={true} />
      }
      <div className="text-center">
        <p className="hidden md:block">
          ¿Ya tienes una cuenta?{" "}
          <span
            className="text-sc-titles"
            onClick={onSignInRequest}
            style={{ cursor: "pointer" }}
          >
            <b>Inicia sesión</b>
          </span>
        </p>
        <p className="block md:hidden">
          ¿Ya tienes una cuenta?
          <Link
            className="inline-block md:hidden mb-6 mt-6 text-sc-titles"
            to="/pwa/account/login"
            style={{ cursor: "pointer" }}
          >
            <b> Inicia sesión</b>
          </Link>
        </p>
      </div>
    </div>
  )
}

export default PartnerSignUpForm
